// import logo from './logo.svg';
import "./App.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Links from "./components/Links";

import profilePic from "./Images/profile.jpg";

function App() {
  const [location, setLocation] = useState([]);
  const [hide, setHide] = useState(true);
  useEffect(() => {
    const getLocation = async () => {
      let data = await axios.get("https://api.db-ip.com/v2/free/self");
      setLocation(data.data.countryCode);
      axios.post("/.netlify/functions/save", { data: data.data });
      if (location === "ZA") {
        setHide(true);
      } else {
        setHide(false);
      }
    };
    getLocation();
  }, [location]);
  return (
    <div>
      <header></header>

      <title>Come Play With Abby</title>
      <link rel="icon" href="/favicon.ico" />

      <div className="flex flex-col items-center justify-center mt-5">
        <img
          width={300}
          height={300}
          className="mx-auto rounded-full"
          src={profilePic}
          alt="Play With Abby"
          // layout={'fill'} objectFit={'contain'}
        />
        <h2 className="my-3 text-3xl font-bold text-center text-indigo-900 md:text-4xl">
          Play With Abby
        </h2>
      </div>

      <Links location={location} hide={hide} />
    </div>
  );
}

export default App;
