import Link from "./Link";

import twitchIcon from "../Images/twitch.jpg";
// import manyVidsIcon from "../Images/manyvids.jpg";
// import onlyFansIcon from "../Images/onlyfans.jpg";
// import redditIcon from "../Images/reddit.jpg";
import tiktokIcon from "../Images/tiktok.jpg";
import hoverIcon from "../Images/hover.jpg";
// import twitterIcon from "../Images/twitter.jpg";
// import fanCentroIcon from "../Images/profile.jpg";
import instagramIcon from "../Images/instagram.jpg";
import youtubeIcon from "../Images/youtube.jpg";

function Links(props) {
  return (
    <div className="items-container">
      <Link
        image={hoverIcon}
        link="https://hover.gg/Astro_Abby"
        text="Join me on Hover (Referal Code=SWRLVQ)!"
        hide={props.hide}
        nsfw={false}
      />

      <Link
        image={twitchIcon}
        link="https://www.twitch.tv/astro_abby"
        text="Watch my Twitch Stream!"
        hide={props.hide}
        nsfw={false}
      />
      <Link
        image={tiktokIcon}
        link="https://www.tiktok.com/@astro_abby_"
        text="Watch me on TikTok"
        hide={props.hide}
        nsfw={false}
      />
      {/* <Link
        image={fanCentroIcon}
        link="https://fancentro.com/playingwithabby"
        text="Become a Fan on FenCentro (NSFW)"
        hide={props.hide}
        nsfw={true}
      /> */}
      {/* <Link
        image={manyVidsIcon}
        link="https://www.manyvids.com/Feed/Come-Play-With-Abby/1004976007"
        text="See my Videos on ManyVids! (NSFW)"
        hide={props.hide}
        nsfw={true}
      /> */}
      {/* {console.log(props.hide)} */}
      {/* <Link
        image={onlyFansIcon}
        link="https://onlyfans.com/playwithabby "
        text="Subscribe to my OnlyFans (NSFW)"
        hide={props.hide}
        nsfw={true}
      /> */}
      {/* <Link
        image={redditIcon}
        link="https://www.reddit.com/user/come_play_with_abby"
        text="Follow me on reddit"
        hide={props.hide}
        nsfw={true}
      /> */}
      {/* <Link
        image={twitterIcon}
        link="https://twitter.com/Playingwithabby?s=09"
        text="Catch me on Twitter"
        hide={props.hide}
        nsfw={false}
      /> */}
      <Link
        image={instagramIcon}
        link="https://www.instagram.com/_astro_abby_/"
        text="See my pictures on Instagram"
        hide={props.hide}
        nsfw={false}
      />
      <Link
        image={youtubeIcon}
        link="https://www.youtube.com/channel/UC9wQDmbgqG2yGsZguMY8oCA"
        text="My Youtube Channel"
        hide={props.hide}
        nsfw={false}
      />
      <Link
        image={twitchIcon}
        link="https://twitch-sa.co.za"
        text="Twitch SA Streamers Guide"
        hide={props.hide}
        nsfw={false}
      />
    </div>
  );
}

export default Links;
